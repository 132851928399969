import * as Sentry from '@sentry/sveltekit';
import { env } from '$env/dynamic/public';

if (env.PUBLIC_SENTRY_DSN) {
	let stage = env.PUBLIC_STAGE;
	if (stage) {
		stage = stage.toUpperCase();
	} else {
		// CHANGED?!?
		stage = 'local';
	}
	Sentry.init({
		dsn: env.PUBLIC_SENTRY_DSN,
		environment: stage,

		// The docs (https://docs.sentry.io/platforms/javascript/guides/sveltekit/manual-setup/)
		// recommend adjusting this value in production, or using tracesSampler
		// for finer control
		tracesSampleRate: 0.5
	});
}

export const handleError = Sentry.handleErrorWithSentry();
