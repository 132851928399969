import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/auth_successful": [~4],
		"/compare": [5],
		"/detail/[fund_id=sfid]/[[share_class_id=sfid]]": [~6],
		"/fund-experience": [7],
		"/fund-structure-v2": [8],
		"/portfolio": [9],
		"/search": [10],
		"/[lang=lang]/[products=products]/[isin=isin]": [~3]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';